/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import 'core-js/stable'
import 'regenerator-runtime/runtime'

require('@rails/ujs').start()
import ReactRailsUJS from 'react_ujs'
window.I18n = require('i18n-js')

require('../i18n/translations')
require('bootstrap')
require('select2')
require('webshim/js-webshim/minified/polyfiller')
require('jquery.are-you-sure')
require('jquery-match-height')
require('slick-carousel')
require('bootstrap-datepicker')

// application modules
import '../application/analytics'
import '../application/analytics_wrapper'
import '../application/popover'
import '../application/retina'
require('../turbosquid/dashboard_widgets/index')

require('../application/publish_products')
require('../application/sso_logout')
require('../application/parts_library')
require('../application/products')
require('../application/simple_sidebar')
require('../application/quick-preview')
require('../application/stemcell')
require('../application/pixelsquid')

import * as MyProductsApp from '../turbosquid/MyProductsApp'
import PublisherApplication from '../turbosquid/products/publisher_application'
import BundlesPublisherApplication from '../turbosquid/products/bundles_publisher'
import * as MyDraftsApp from '../turbosquid/MyDraftsApp'

// needed for inline script tags that expect global jquery
window.$ = window.jQuery = $
window.MyProductsApp = MyProductsApp
window.PublisherApplication = PublisherApplication
window.MyDraftsApp = MyDraftsApp
window.BundlesPublisherApplication = BundlesPublisherApplication

require('../tax/interview')
require('../application/pages')
require('../prohibited_brands/prohibited_brands')

$(() => {
  ReactRailsUJS.mountComponents()
  $('[data-toggle="tooltip"]').tooltip()
  $('#page-content-wrapper').css('min-height',$(window).height())
  $('.select2-select').select2({
    allowClear: true,
    placeholder: 'Select an option',
    width: '200px'
  })
})
