/* global I18n, gon */

import AnalyticsWrapper from '@shutterstock-private/analytics-wrapper'

const APPLICATION_NAME = 'turbosquid_artist'

const analyticsWrapper = new AnalyticsWrapper({
  debugMode: true,
  gtm: {
    enabled: true,
    id: 'GTM-ML7LRQS'
  },
  hightouch: {
    enabled: gon.enable_hightouch,
    writeKey: gon.hightouch_key,
  },
})

export const pageViewedData = {
  pageType: window.location.pathname,
  site: 'turbosquid_artist',
}
  
analyticsWrapper && analyticsWrapper.loadProvider()
  .then(() => {
    const initialData = {
      page: {
        applicationName: APPLICATION_NAME,
        pageLanguage: 'en_US',
        environment: process.env.NODE_ENV,
        originalUrl: 'https://squid.io',
        referringUrl: document.referrer,
      },
      user: {
        id: gon.user_id,
        isActive: false,
        status: 'user',
        user_type: '',
      },
    }

    analyticsWrapper.setInitialData(initialData)
    analyticsWrapper.startListener()

    const userId = String(gon.user_id)
    if (userId) {
      analyticsWrapper?.identify(userId, {email: ''})
    }

  })

analyticsWrapper?.page(pageViewedData)

export default analyticsWrapper
