import React, { useCallback, useContext, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { ProductContext } from '../../contexts/ProductContext'
import { BundlesContext } from '../../products/bundles/BundlesProvider'
import FormError from './ProductFiles/FormError'
import { HIGHER_PRICE_BOUND, LOWER_PRICE_BOUND, validate, stripHTMLFromString, clearTitleAndTagsForbiddenWords
} from '../../products/react_components/helpers'

const i18nOptions = {
  scope: 'turbosquid.products.product_name_panel',
}

const bundlesi18Options = {
  scope: 'turbosquid.products.bundles_edit_panel'
}

const zeroOrGreater = (value) => {
  const castValue = parseFloat(value)
  const isValid = castValue >= 0
    ? castValue === 0 || (castValue >= LOWER_PRICE_BOUND && castValue <= HIGHER_PRICE_BOUND)
    : true
  return isValid
    ? true
    : <>
        {`${I18n.t('price_validation_max', {
          ...i18nOptions,
          upper_bounds: HIGHER_PRICE_BOUND,
          lower_bounds: LOWER_PRICE_BOUND,
        })} `}
        <a target="_blank" rel="noopener noreferrer" href={I18n.t('terms_agreement_url', { ...i18nOptions })}> </a>
      </>
}

export const errorMessage = (translation, options = {}) => {
  return (
    <>
      <a onClick={() => {}} className="field-link">
        {`${I18n.t('price', i18nOptions)}: `}
      </a>
      <span className="error-messages">{I18n.t(translation, { ...i18nOptions, ...options })}</span>
    </>
  )
}

export default function ProductPrice() {
  const { register, formState: { errors } } = useFormContext()
  const { product } = useContext(ProductContext)

  const initialValue = useCallback(() => product.price, [])
  const inputName = 'product-price'
  const {
		metadataForm,
    searchSelect
	} = useContext(BundlesContext)

	const {
		bundlePrice,
		setBundlePrice
	} = metadataForm

  const {
    totalPrice
  } = searchSelect

  const sanitizeValue = (value) => {
    return clearTitleAndTagsForbiddenWords(
      stripHTMLFromString(setBundlePrice(value))
    ).trim()
  }

  const discount = (totalPrice) => {
    return ((Math.round(totalPrice * 100) / 100) * .9).toFixed()
  }
  
  const handleValidation = (value) => {
    return validate(Number(value), [
      zeroOrGreater,
    ])
  }
  return (
    <div className="form-group turbosquid_product_form_price" style={{width: '100%'}}>
      <label className="control-label" htmlFor={inputName}>
        {I18n.t('price', i18nOptions)}
      </label>
      <input
        type="number"
        step="1"
        id={inputName}
        data-testid="price-input"
        name={inputName}
        value={bundlePrice}
        onChange={(e) =>  {
          const value = e.target.value
          if (/^\d*$/.test(value)) {
            setBundlePrice(Number(e.target.value).toFixed())}
          }
        } 
        {...register(inputName, {
          defaultValue: Number(product.price).toFixed(),
          value: Number(product.price).toFixed(),
          required: {
            value: true,
            short: 'Required',
            message: errorMessage('price_required'),
          },
          validate: handleValidation,
          setValueAs: sanitizeValue
        })}
        className="form-control"
      />
      <div>
        {I18n.t('total_price', bundlesi18Options)} <span data-testid="total-price">{`$${totalPrice}`}</span>
      </div>
      <div>
        {I18n.t('recommended_price1', bundlesi18Options)} <span data-testid="recommended-price">{`$${discount(totalPrice)}`}</span> {I18n.t('recommended_price2', bundlesi18Options)}
      </div>
      {errors[inputName] && <FormError error={errors[inputName]} />}
    </div>
  )
}
